<template>
  <StartEffectEvaluation
    @startEvaluation="startEvaluation"
  ></StartEffectEvaluation>
</template>

<script> 
import StartEffectEvaluation from "@/components/summary-page/start-effect-evaluation.vue";
import {getTrainingFinish} from "@/api/english-big"
export default {
  data(){
    return{
      finishPage:''
    }
  },
  components: {
    StartEffectEvaluation,
  },
  created(){
    this.getPageData()
  },
  methods: {
    getPageData(){
       getTrainingFinish({}).then((res) => {
        console.log("res", res);
        this.finishPage = res.data.result;
      });
    },
    startEvaluation() {
      this.$router.replace({
        name: "BigModuleEffectEvaluation", 
        query: { 
          // module: 2,
          examId: this.finishPage.examId || this.$route.query.examId,
          sprintId: 4,
          stage:3
        },
      });
    },
  },
};
</script>

<style>
</style>